.aboutContainer {
  background-color: #0a0b0f;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-top: 2rem;
  padding-bottom: 5.125rem;
  position: relative;
  z-index: 0;
}
.WhoImI::after {
  content: "";
  display: block;
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 10%; /*antes "calc(20vw - 2rem);"*/
  z-index: 2;
  width: 2px;
  opacity: 1;
  background: linear-gradient(0deg, #fff0, rgb(73 150 167 / 33%), #8675d1);
}
.WhoImI {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}
.WhoImI h2 {
  margin: 0;
  margin-bottom: 2rem;
  font-size: 4rem;
  line-height: 1;
  font-weight: bold;
  color: #e4e4e4;
}
.pComment {
  font-size: 1.15rem;
  color: #808080;
}
.AboutP {
  font-size: 1.15rem;
  color: #e4e4e4;
}
.myNameSpan {
  color: #8675d1;
}
.iconContainer {
  display: flex;
  margin-bottom: 1rem;
  padding: 0;
}
.iconContainer li {
  margin: 0 1rem 0 0;
  list-style-type: none;
}
.iconContainer li svg {
  color: white;
  transition: all 200ms ease-in;
}
.iconLi:hover svg {
  color: rgb(126, 126, 126);
  transform: scale(105%);
}
.iconContainer li a {
  width: 100%;
  height: 100%;
}
/*CV*/
.curriculum-li{
  position: relative;
 
}
.all-cv-container{
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%; 
  left: -32%;
  background-color: rgba(0, 0, 0, 0.216); 
  padding: 10px;
  transition: opacity 0.3s ease; 
}
.all-cv-container a{
  padding: 0.2rem 1rem;
  margin-bottom: 0.5rem;
}
.all-cv-container a:hover{
  margin-bottom: 0.5rem;
}
.curriculum-li:focus-within .all-cv-container,
.curriculum-li:hover .all-cv-container {
  opacity: 1;
  pointer-events: all;
}
/*^^*/
.aRepRap {
  box-shadow: inset 0 0 0 0 #9ed9e8;
  text-wrap: nowrap;
  color: #80c4c4;
  font-weight: 700;
  line-height: 1.5;
  text-decoration: none;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.aRepRap:hover {
  color: #0a0b0f;
  box-shadow: inset 500px 0 0 0 #b59ee8;
}
.aComment {
  box-shadow: inset 0 0 0 0 #f9b4ff8f;
  text-wrap: nowrap;
  color: #808080;
  text-decoration: none;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.aComment:hover {
  color: #0a0b0f;
  box-shadow: inset 300px 0 0 0 #d6f7ad;
}
.skillsContainer {
  color: #e4e4e4;
  padding: 2rem 0;
}
.skillsContainer h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
.skillsContainer ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 0;
  margin-bottom: 4rem;
}
.skillsContainer ul li {
  list-style-type: none;
}
.skillsContainer p {
  font-size: 1.1rem;
  color: #a5a5a5;
  margin: 0;
}
.skillsContainer span {
  font-size: 1.1rem;
  background-image: linear-gradient(45deg, #a793ff, #b0efff);
  background-clip: text;
  color: transparent;
  margin: 0;
  font-weight: 600;
}
.buttonCv {
  background: #afeaff;
  border-radius: 10px;
  padding: 0 0.5rem;
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  width: 100%;
  transition: all 100ms ease-in;

}
.buttonCv svg{
  margin-left: 0.5rem;
}
.buttonCv:hover{
  background: #80c4c4;
}

@media screen and (max-width: 790px) {
  .WhoImI::after {
    top: 5.5%;
  }
  .skillsContainer ul {
    grid-template-columns: repeat(2, 1fr);
  }
  .WhoImI h2 {
    font-size: 3rem;
  }
  .skillsContainer p {
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .WhoImI h2 {
    font-size: 1.5rem;
    word-wrap: break-word;
  }
  .skillsContainer ul {
    grid-template-columns: auto;
  }
  .skillsContainer p {
    max-width: 100%;
  }
}
