.liCards {
  list-style: none;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 8px 50px 0px rgba(0, 0, 0, 0.85);
  transition: transform 200ms ease-in;
}
.liCards:hover {
  transform: scale(105%);
  cursor: pointer;
}
.liCards:hover .cardContainer::after {
  transform: scale(
    100.2%
  ); /*esto es por la linea blanca que se forma, no se si es lo mejor y mas eficicente,*/
}
.cardContainer {
  position: relative;
  height: 300px;
  border-radius: 10px;
  text-align: center;
  z-index: 0;
}
.cardContainer h1 {
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.cardContainer p {
  color: #ffffff;
  margin: 0rem 1rem;
  text-wrap: balance;
  opacity: 0;
  transform: translateY(15px);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.tecnologiesCardContainer {
  position: absolute;
  bottom: 1rem;
  left: 0;
  padding: 0 0.5rem;
  color: white;
  backdrop-filter: blur(10px);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
}
.tecnologiesCards {
  padding: 0 1rem;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.tecnologiesCards img {
  height: 24px;
  width: 24px;
}

.cardContainer:hover p {
  opacity: 1;
  transform: translateY(0px);
}
.cardContainer:hover span {
  opacity: 0;
  transform: translateY(-5px);
}

.cardContainer::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: linear-gradient(180deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.138));
  opacity: 50%;
  z-index: -1;
  border-radius: 10px;
  transition: all 200ms ease-in;
}
.cardContainer:hover::after {
  opacity: 80%;
  background-image: linear-gradient(180deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.656));
}
