body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
:root {
  --bs-body-bg: #0a0b0f;
  font-size: 16px;
}
@font-face {
  font-family: "DM Serif Display";
  src: url(./fonts/DMSerifDisplay-Italic.ttf);
}
@font-face {
  font-family: "Rajdhani";
  src: url(./fonts/Rajdhani-Regular.ttf);
}

/*Home Page*/

.principal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
  background: #0a0b0f;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 0 4rem;
  max-width: 1200px;
}
.principal p {
  margin: 0;
}
.identity {
  color: #a793ff;
  font-size: 19px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  padding-bottom: 1rem;
  padding-top: 10px;
}
.presentation {
  display: flex;
  font-size: 5rem;
  line-height: 1;
  font-weight: bold;
  font-family: "DM Serif Display";
  color: #ffffff;
  margin-bottom: 2rem;
  padding-top: 0px;
}
.presentation p {
  padding-right: 1.5rem;
}
.bio {
  font-size: 1.8rem;
  font-weight: lighter;
  color: #dad9d9;
  margin: 0;
}
.bio p {
  padding-bottom: 10px;
}
.typewriterText {
  color: rgb(128, 196, 196);
}
.myCard {
  padding-top: 100px;
  text-align: center;
  margin: 0px 0px 0px 0px;
  padding-left: 0px;
}

.secondaryContainer {
  background-color: #12141a;
}
.secondaryDiv {
  background-color: #12141a;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}
.secondaryDiv h1 {
  color: #e4e4e4;
  font-family: "Rajdhani";
  font-weight: bolder;
  padding: 2rem 0;
  margin: 0;
}
.secondaryDiv ul {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  margin: 0;
  padding: 0;
  padding-bottom: 5rem;
}
.experimental-rocketry-a{
  color: #b59ee8;
  background-image: linear-gradient(#e8c39e 0 0);
  background-size: 0%;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size 300ms, background-position 0s 300ms;
}
.experimental-rocketry-a:hover{
  text-decoration: none;
  color: black;
  background-image: linear-gradient(#b59ee8 0 0);
  background-position: 100% 100%;
  background-size: 100%;
}

/*Header*/

.navbar {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding: 3.125rem 0;
  font-size: 1rem;
}
.navbar-brand {
  background-color: #0a0b0f;
  display: flex;
  align-items: center;
  color: var(--color-text);
  font-weight: 700;
}
.navbar-light .navbar-nav .nav-link {
  color: #8d8d8d;
  border-bottom: 3px solid #0a0b0f;
  margin-right: 0.825rem;
  margin-left: 0.825rem;
}
.navbar-light:hover .navbar-nav:hover .nav-link:hover {
  color: rgb(255, 255, 255);
  border-bottom: 3px solid rgb(72, 72, 72);
  margin-right: 0.825rem;
  margin-left: 0.825rem;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #ffffff;
  border-bottom: 3px solid #80c4c4;
  margin-right: 0.825rem;
  margin-left: 0.825rem;
}
.header_brand_bio {
  background-color: #0a0b0f;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.myImage {
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  object-fit: cover;
}
.SpanName {
  color: white;
  font-size: 1rem;
  font-weight: 700;
  line-height: 0;
}
.collapseButtonNav {
  border: none;
}

/*Contact*/
.form-control::placeholder {
  color: #bcbcbc;
}
.custom-btn {
  background-color: #80c4c4;
  color: #0a0b0f;
}
.custom-btn:hover {
  background-color: #8675d1;
  color: #0a0b0f;
}
.contactContainer {
  text-align: center;
  margin: 2rem 0;
}
.contactContainer p {
  background-image: linear-gradient(45deg, #a793ff, #b0efff);
  background-clip: text;
  color: transparent;
  font-weight: 400;
  margin-top: 1rem;
}
.line-separator {
  flex: 1;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0;
}
.text-separator {
  padding: 0 1rem;
  color: #666;
  font-size: 1rem;
}
.separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin: 2rem 0;
}

/*Hobbies*/
.principalHobbies h1 {
  margin: 0;
}
.LeftHobbieContainer {
  background-color: #0a0b0f;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6.25rem;
  margin-bottom: 3.125rem;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
}
.imageHobbie {
  height: auto;
  width: 50%;
  text-align: center;
}
.imageHobbie img {
  max-width: 100%;
}
.leftTextHobbies {
  height: auto;
  width: 50%;
  text-align: center;
  color: #e4e4e4;
  font-family: "Rajdhani";
}
.leftTextHobbies h1 {
  font-weight: bold;
  padding: 1rem;
}
.leftHobbieHr {
  width: 20%;
  padding: 0;
  margin: 0;
  margin-left: 1rem;
}
.leftTextHobbies p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: lighter;
  padding: 1rem;
  margin: 0;
}
.rightHobbieContainer {
  background-color: #0a0b0f;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3.125rem;
  padding-top: 6.25rem;
  max-width: 1200px;
  display: flex;
  flex-direction: row-reverse;
}
.rightHobbieHr {
  width: 20%;
  padding: 0;
  margin: 0;
  margin-left: auto; /* Alinea a la derecha */
  margin-right: 1rem;
}

.aOpenSource {
  box-shadow: inset 0 0 0 0 #54b3d6;
  color: #54b3d6;
  font-weight: 700;
  line-height: 1.5;
  text-decoration: none;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.aOpenSource:hover {
  color: #0a0b0f;
  box-shadow: inset 200px 0 0 0 #54b3d6;
}
.aPhotos {
  box-shadow: inset 0 0 0 0 #e8c39e;
  color: #e8c39e;
  font-weight: 700;
  line-height: 1.5;
  text-decoration: none;
  text-wrap: nowrap;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.aPhotos:hover {
  color: #0a0b0f;
  box-shadow: inset 200px 0 0 0 #e8c39e;
}
.aChess {
  box-shadow: inset 0 0 0 0 #e8c39e;
  color: #b59ee8;
  font-weight: 700;
  line-height: 1.5;
  text-decoration: none;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.aChess:hover {
  color: #0a0b0f;
  box-shadow: inset 200px 0 0 0 #b59ee8;
}
/*Footer*/
.footerCustom {
  color: #fff;
  text-align: center;
  background-color: #0a0b0f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-top: 1px solid #262626;
}
.lighthouse-stats {
  display: flex;
  flex-direction: row;
}
.stats {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  border: 3px #00cc66 solid;
  background-color: #1e3328;
  position: relative;
  font-size: 0.85rem;
  margin-right: 0.5rem;
  transition: all 200ms ease-in;
}
.number-lighthouse {
  position: absolute;
  top: 49%;
  left: 49%;
  transform: translate(-50%, -50%);
  color: #00cc66;
  pointer-events: none;
}
.stats:hover {
  scale: 105%;
}

/* Media's Responsiveness */
@media screen and (max-width: 768px) {
  /*Home*/
  .principal {
    min-height: auto;
  }
  .identity p {
    font-size: 1rem;
  }
  .bio p {
    font-size: 1.25rem;
  }
  .presentation {
    display: block;
    font-size: 3rem;
  }
  .presentation p {
    padding: 0;
  }
  .secondaryDiv h1 {
    font-size: 1.75rem;
  }
  /*Hobbies*/
  .LeftHobbieContainer {
    flex-direction: column;
    align-items: center;
  }
  .imageHobbie {
    width: auto;
    height: auto;
  }
  .leftTextHobbies {
    height: auto;
    width: auto;
    text-align: center;
    color: #e4e4e4;
    font-family: "Rajdhani";
  }
  .leftTextHobbies {
    height: auto;
    width: auto;
    text-align: center;
    color: #e4e4e4;
    font-family: "Rajdhani";
  }
  .rightHobbieContainer {
    flex-direction: column;
    align-items: center;
  }
  /*FOOTER*/
  .footerCustom {
    flex-direction: column;
  }
  .lighthouse-stats {
    padding: 1rem;
  }
}
